.virtual-grid {
  background-color: #fafafa;
  color: rgb(94, 94, 94);
}

.shadow-after::after {
  position: absolute;
  top: 0;
  right: 2px;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: "";
  pointer-events: none;
  box-shadow: inset 9px 0 6px -7px rgba(0, 0, 0, 0.10);
  z-index: 100;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
  overflow-x: scroll !important;
}

.il-data-table .react-resizable {
  position: relative;
  background-clip: padding-box;
}

.virtual-table-cell {
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  background: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.virtual-table-cell-header {
  background-color: #fafafa;
  font-weight: bold;
}

.virtual-table-cell.hovered-cell {
  background: #fbfbfb;
}

.virtual-table-cell.selected-cell {
  background: #fff9d7;
  border-color: #f0e39a;
}

.il-data-table .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
  transition: 0.2s background-color;
}

.il-data-table .react-resizable-handle:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.il-data-table .react-resizable-handle:active {
  background-color: #ffdd79;
}

/*** Dark Theme ***/
.virtual-grid-yellow {
  background-color: #f0eac8;
  color: var(--gold-tired-sand);
}

.virtual-grid-yellow .virtual-table-cell {
  border-color: #f0e39a;
  background-color: #fff9d7;
}

.virtual-grid-yellow .virtual-table-cell.hovered-cell {
  background: #f0eac8;
}

.virtual-grid-yellow .virtual-table-cell.selected-cell {
  background: #f0eac8;
}

.grid-header-row-yellow .virtual-table-cell-header {
  background-color: #f0eac8 !important;
  border-color: #f0e39a;
  color: #5b4800;
}

/**** Selectable ****/
.selectable-data-table .virtual-table-cell {
  cursor: pointer !important;
}